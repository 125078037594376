import Swiper, { Navigation, HashNavigation, Lazy, Autoplay, EffectFade } from 'swiper';

Swiper.use([Navigation, HashNavigation, Lazy, Autoplay, EffectFade]);

const swiper = new Swiper('#carousel', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 40,
    hashNavigation: {
        watchState: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
            spaceBetween: 20
        },
    }
});