import 'tiny-editor';


const testForms = document.querySelectorAll(".form_js-validate");
window.formComponent = {
    init: () => {
        testForms.forEach((form) => {
            const fields = form.querySelectorAll("[data-validate]");
            // formComponent.validationOnSubmit(fields, form);
            formComponent.validateOnEntry(fields, form);
        });
    },

    validationOnSubmit: (validationFields, form) => {
        form.addEventListener("submit", (e) => {

            validationFields.forEach((field) => {
                formComponent.validateFields(field);
            });
            let checks = form.querySelector(".error:not(.hidden)");
            if (form.contains(checks)) {
                e.preventDefault();
            } else {
                formSending();
            }

        });
    },

    validateOnEntry: (validationFields, form) => {
        validationFields.forEach((field) => {
            field.addEventListener("input", (event) => {
                formComponent.validateFields(field);
            });
        });
    },

    validateFields: (input) => {
        const data = input.dataset.validate;

        if (~data.indexOf("no-empty")) {
            if (input.value.trim() === "") {
                formComponent.setStatus(input, "error");
            } else {
                formComponent.setStatus(input, "success");
            }
        }
        if (~data.indexOf("number")) {
            const numReg = /^\d+$/;
            if (numReg.test(input.value) && input.value.trim() !== "") {
                formComponent.setStatus(input, "success");
            } else {
                formComponent.setStatus(input, "error");
            }
        }
        if (~data.indexOf("email")) {
            const emailReg = /\S+@\S+\.\S+/;
            if (emailReg.test(input.value) && input.value.trim() !== "") {
                formComponent.setStatus(input, "success");
            } else {
                formComponent.setStatus(input, "error");
            }
        }

        if (~data.indexOf("select")) {
            if (input.value === "") {
                formComponent.setStatus(input, "error");
            } else {
                formComponent.setStatus(input, "success");
            }
        }
        if (~data.indexOf("checkbox")) {
            // console.log(input.checked);
            if (input.checked) {
                formComponent.setStatus(input, "success");
            } else {
                formComponent.setStatus(input, "error");
            }
        }

        if (~data.indexOf("phone")) {
            const phoneReg =
                /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
            if (phoneReg.test(input.value) && input.value.trim() !== "") {
                formComponent.setStatus(input, "success");
            } else {
                formComponent.setStatus(input, "error");
            }
        }
    },

    // set/remove class final fx
    setStatus: (field, status) => {
        let errorMessage = field.parentElement.querySelector(".error");

        if(!errorMessage){
            errorMessage = field.parentElement.parentElement.querySelector(".error");
        }

        // console.log(errorMessage);
        if (status === "success") {
            field.classList.remove("input-error");
            errorMessage.classList.add("hidden");
        }
        if (status === "error") {
            field.classList.add("input-error");
            errorMessage.classList.remove("hidden");
        }
    },
};



const   cbx1 = document.getElementById('agree');
if(!!cbx1){
    cbx1.addEventListener('change', () => {
        checkParam();
    });
    function checkParam() {
        const btn = document.getElementById('submit');

        if (cbx1.checked){
            btn.removeAttribute('disabled');
        } else {
            btn.setAttribute('disabled', '');
        }
    }
}


// Result message
const   resInput                = document.getElementById('inputMessage'),
        resMessage              = document.getElementById('resultMessage'),
        editor                  = document.querySelector('[data-tiny-editor]'),
        txtCount                = 500,
        // lineCount               = 10,
        charElem                = document.querySelector('.js-count'),
        lineElem                = document.querySelector('.js-count_line');





if(!!editor && !!resMessage && !!resInput){
    let textClear, textFormat, textFormatLen, textRemain, l_count;
    editor.addEventListener('keydown', function(e){
        //remove content editable
        textClear = editor.innerText;
        textFormat = textClear.trim().replace(/[\t\n]+/g, '');
        textFormatLen = textFormat.length;
        textRemain = txtCount - textFormatLen;

        // l_count = editor.innerText.split('\n').length;

        // console.log(e);

        /*---Prevent Enter Key---*/
        // if((e.which == 13 && parseInt(l_count) > lineCount)){
        //     e.preventDefault();
        //     return false;
        // }

        // /*---Prevent Copy Paste---*/
        // if (e.ctrlKey==true && (e.which == '118' || e.which == '86')) {
        //     e.preventDefault();
        //     return false;
        // }


        // if((textRemain > 0) && (lineCount - parseInt(l_count) > 0)){
        if((textRemain > 0)){
            resInput.value = editor.innerHTML;
            resMessage.innerHTML = editor.innerHTML;

            // console.log(editor.innerHTML);
            // console.log(editor.innerText.split('\n').length);
            if(charElem != undefined){
                charElem.innerText = textRemain;
            }
            if(lineElem != undefined){
                lineElem.innerText = lineCount - parseInt(l_count);
            }

        }
        else{
            if(e.which == 8 || e.which == 46){
            } else {
                e.preventDefault();
            }
        }
    })
    editor.addEventListener('click', function(){
        editor.contentEditable = 'true';
    })
}


formComponent.init();
