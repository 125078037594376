import './components/formComponent';
import './components/carousel';
import './components/animations';
import './components/copyText';

// Main js file
let isTouch = false;
if ('ontouchstart' in document.documentElement) {
    isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';

const mainWrap = document.querySelector('main');

const langSwitch = document.querySelectorAll('.lang-switch');
langSwitch.forEach(el => {
    if(el.classList.contains('on')){
        el.parentElement.classList.add('is-active');
    }
});