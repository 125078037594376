import { gsap } from 'gsap';
import {WOW} from 'wowjs';
import VanillaTilt from 'vanilla-tilt';

// Fadein animation
new WOW(
    {
        boxClass: 'wow',
        animateClass: 'animated',
        mobile: false
    }
).init();

// Card hover effect
VanillaTilt.init(document.querySelectorAll(".card"));